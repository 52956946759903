import { TheHousingHubLoading } from "app/components"; // Assuming you have a loading component
import axios from "axios";
import Cookies from "js-cookie";
import { createContext, useEffect, useReducer } from "react";
// Initial authentication state
const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false
};

// Reducer function to handle state transitions
const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }
    case "LOGIN": {
     
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }
    case "LOGOUT": {
    
      return { ...state, isAuthenticated: false, user: null };
      
    }
    case "REGISTER": {
     
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default:
      return state;
  }
};

// Create the authentication context
const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {}
});

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Function to handle user login
  const login = async (email, password) => {
    try {
      const response = await axios.post("/api/auth/login", { email, password });
      const { user } = response.data;
      dispatch({ type: "LOGIN", payload: { user } });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  // Function to handle user registration
  const register = async (email, username, password) => {
    try {
      const response = await axios.post("/api/auth/register", { email, username, password });
      const { user } = response.data;
      dispatch({ type: "REGISTER", payload: { user } });
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  // Function to handle user logout
  const logout = () => {
    const removeCookies = () => {
      Cookies.remove('userData');
      Cookies.remove('token');
      console.log('LogoutFunction');
    };
    removeCookies();
    dispatch({ type: "LOGOUT" });
  };

  // Effect hook to initialize authentication state
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const { data } = await axios.get("/api/auth/profile");
        dispatch({ type: "INIT", payload: { isAuthenticated: true, user: data.user } });
      } catch (error) {
        console.error("Initialization error:", error);
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    };

    initializeAuth();
  }, []);

  // Show loading spinner while initializing
  if (!state.isInitialized) return <TheHousingHubLoading />;

  // Provide authentication context value to children
  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
