import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import TheHousingHubLayout from "./components/TheHousingHubLayout/TheHousingHubLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// const Sites = Loadable(lazy(() => import("app/views/master/sites/sites")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const AddPlot = Loadable(lazy(() => import("app/views/master/plots/addPlots")));
const AddSites = Loadable(lazy(() => import("app/views/master/sites/addSites")));
const AddUsers = Loadable(lazy(() => import("app/views/crm/users/addUsers.js")));
const AddBrokers = Loadable(lazy(() => import("app/views/crm/brokers/addBrokers.js")));
const Enquiry = Loadable(lazy(() => import("app/views/crm/enquiry/enquiry.js")));


const routes = [
  {
    element: (
      <AuthGuard>
        <TheHousingHubLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard/default",
        element: <Analytics />,
        auth: authRoles.admin
      },
      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor
      },
      {
        path: "app/views/dashboard/Analytics",
        auth: <authRoles className="guest"></authRoles>
      },
      // Plots
      {
        path: "/master/plots/plots/addPlot",
        element: <AddPlot />
      },
      {
        path: "/crm/users/users/addUsers",
        element: <AddUsers />
      },
      
      {
        path: "/crm/brokers/brokers/addBrokers",
        element: <AddBrokers />
      },
      {
        path: "/master/sites/sites/addSites",
        element: <AddSites />
      },
      {
        path: "/crm/enquiry/addEnquiry/enquiry",
        element: <Enquiry />
      },
     
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> }

  // {
  //   path: "/master/plots/plots/addPlot",
  //   element: <AddPlot />,
  // },
];

export default routes;
